import React, { useState, useEffect } from "react";
import "./font.css";
import logo from "../assets/img/LogoNeural.png";
import face from "../assets/img/face.png";
import insta from "../assets/img/insta.png";
import whats from "../assets/img/whats.png";
import email from "../assets/img/email.png";

export const Foot = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = (e) => {
      setIsMobile(e.matches);
    };

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  return (
    <div style={{ height: "35vh", backgroundColor: "#222", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: isMobile ? "20vh" : "0vh" }}>
      <div style={{ display: "flex", alignItems: "center", marginTop: isMobile ? "2vh" : "4vh" }}>
        <a href="https://www.facebook.com/neuralproducciones/" target="_blank" rel="noopener noreferrer">
          <img src={face} alt="Logo" style={{ marginRight: isMobile ? "3vh" : "5vh", height: isMobile ? "5vh" : "7vh" }} />
        </a>
        <a href="https://www.instagram.com/neuralproducciones/" target="_blank" rel="noopener noreferrer">
          <img src={insta} alt="Logo" style={{ margin: "0 5vh", height: isMobile ? "5vh" : "7vh" }} />
        </a>
        <a href="https://api.whatsapp.com/message/TCFHMK4W3CUMN1?autoload=1&app_absent=0" target="_blank" rel="noopener noreferrer">
          <img src={whats} alt="Logo" style={{ margin: "0 5vh", height: isMobile ? "5vh" : "7vh" }} />
        </a>
        <a href="mailto:neuralproducciones@gmail.com" target="_blank" rel="noopener noreferrer">
          <img src={email} alt="Logo" style={{ marginLeft: isMobile ? "3vh" : "5vh", height: isMobile ? "5vh" : "7vh" }} />
        </a>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: isMobile ? "1vh" : "3vh" }}>
        <img src={logo} alt="Imagen" style={{ width: isMobile ? "15%" : "7%", height: "auto" }} />
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: isMobile ? "1vh" : "3vh" }}>
        <p style={{ color: "#444", margin: "0", fontFamily: "Ras-Med", fontSize: isMobile ? "3vh" : "inherit" }}>© Copyright 2024</p>
      </div>
    </div>
  );
};
