import React, { useState, useEffect } from "react";
import backgroundInicio from "../assets/img/BackgroundInicio.png";
import logo from "../assets/img/LogoNeural.png";
import { Carousel } from "react-bootstrap";
import carousel1 from "../assets/img/Carrousel1.jpeg";   
import carousel2 from "../assets/img/Carrousel2.jpeg";
import carousel3 from "../assets/img/Carrousel3.jpeg";
import "./font.css";

export const Inicio = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 768px)");
        setIsMobile(mediaQuery.matches);

        const handleResize = (e) => {
            setIsMobile(e.matches);
        };

        mediaQuery.addEventListener("change", handleResize);

        return () => {
            mediaQuery.removeEventListener("change", handleResize);
        };
    }, []);

    return (
        <div style={{ position: "relative", height: "100vh" }}>
            <div style={{ backgroundImage: `url(${backgroundInicio})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}></div>
            <div className="overlay" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)', zIndex: -1 }}></div>
            
            {/* Contenido */}
            <div style={{ position: "relative", zIndex: 1, display: "flex", flexDirection: "column", height: "100%" }}>
                {/* Barra horizontal */}
                <div className="header-container" style={{ display: "flex", alignItems: "center", color: "#FFF", height: "18vh", padding: "3vh 3vh 0 3vh" }}>
                    {/* Logo */}
                    <div style={{ flex: 1 }}>
                        <a href="/">
                            <img src={logo} alt="Logo" style={{ maxWidth: "50%", maxHeight: "50%", marginLeft: isMobile ? "10vh" : "0vh"}} />
                        </a>
                    </div>
                    {/* Textos */}
                    {!isMobile && (
                        <div style={{ flex: 3, display: "flex", justifyContent:"flex-end" }}>
                            {/* Cambiando los textos a enlaces */}
                            <a href="https://1drv.ms/f/s!Av8KC8UeANmogZk56aQBJ78w947xoQ?e=la6wfz" style={{ color: "#FFF", textDecoration: "none", margin: "0 2vh", fontFamily: "Ras-Med", fontSize:"3vh"}}>Bodas</a>
                            <a href="https://1drv.ms/f/s!Av8KC8UeANmogZk56aQBJ78w947xoQ?e=la6wfz" style={{ color: "#FFF", textDecoration: "none", margin: "0 2vh", fontFamily: "Ras-Med", fontSize:"3vh"}}>Fiestas 15</a>
                            <a href="https://1drv.ms/f/s!Av8KC8UeANmogZk56aQBJ78w947xoQ?e=la6wfz" style={{ color: "#FFF", textDecoration: "none", margin: "0 2vh", fontFamily: "Ras-Med", fontSize:"3vh"}}>Empresariales</a>
                        </div>
                    )}
                </div>

                {/* División en dos partes */}
                <div style={{ display: "flex", flex: 1 }}>
                    {/* Parte izquierda */}
                    <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection:"column", marginTop: isMobile ? "0" : "-20vh" }}>
                        <img src={logo} style={{ height: isMobile ? "30vh" : "80vh" }}/>
                        <p style={{fontFamily:"Ras-Med", fontSize: isMobile ? "3vh" : "5vh", marginTop: isMobile ? "0" : "-6vh", color: "#FFF", display: "flex", alignItems: "center", textAlign: "center", width:"80%"}}>Capturando Momentos Inolvidables para Toda la Vida</p>
                    </div>
                    {/* Parte derecha */}
                    <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Carousel style={{ maxWidth: isMobile ? '100%' : '50%', maxHeight: isMobile ? "50vh" : "80vh" }}>
                            <Carousel.Item>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        className="d-block"
                                        src={carousel1}
                                        alt="First slide"
                                        style={{objectFit: "cover", maxWidth: '100%', maxHeight: isMobile ? "30vh" : "70vh"}}
                                    />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        className="d-block"
                                        src={carousel2}
                                        alt="Second slide"
                                        style={{ objectFit: "cover", maxWidth: '100%', maxHeight: isMobile ? "30vh" : "80vh"}}
                                    />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        className="d-block"
                                        src={carousel3}
                                        alt="Third slide"
                                        style={{ objectFit: "cover", maxWidth: '100%', maxHeight: isMobile ? "30vh" : "80vh" }}
                                    />
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
};
