import React, { useState, useEffect } from "react";
import sample1 from "../assets/img/Carrousel1.jpeg";
import sample2 from "../assets/img/Carrousel2.jpeg";
import sample3 from "../assets/img/Carrousel3.jpeg";
import "./font.css";

export const Servicios = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = (e) => {
      setIsMobile(e.matches);
    };

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  return (
    <div style={{ height: isMobile ? "auto" : "70vh", display: "flex", flexDirection: "column", alignItems: "center", alignSelf:"center" }}>
      <div style={{ fontSize: isMobile ? "5vh" : "10vh", marginBottom: "3vh", fontFamily: "Ras-Med", color: "#004169" }}>Nuestros Servicios</div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{margin: isMobile ? "3vh 0" : "0 3vh", textAlign: "center", position: "relative", flexBasis: isMobile ? "100%" : "auto"}}>
          <a href="https://1drv.ms/f/s!Av8KC8UeANmogZk56aQBJ78w947xoQ?e=la6wfz" style={{ textDecoration: "none" }}>
            <img src={sample1} alt="Imagen 1" style={{ width: isMobile ? "40vh" : "50vh", height: isMobile ? "40vh" : "50vh", objectFit: "cover", border: "1px solid #ccc", borderRadius: "4vh" }} />
            <div style={{ position: "absolute", top: 0, left: isMobile ? "4vh": "0", width: isMobile ? "40vh" : "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "4vh" }}></div>
            <div style={{ fontSize: "6vh", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "#FF69B4", zIndex: 1, fontFamily: "Ras-Med" }}>Cumples 15</div>
          </a>
        </div>
        <div style={{ margin: isMobile ? "3vh 0" : "0 3vh", textAlign: "center", position: "relative", flexBasis: isMobile ? "100%" : "auto" }}>
          <a href="https://1drv.ms/f/s!Av8KC8UeANmogZk56aQBJ78w947xoQ?e=la6wfz" style={{ textDecoration: "none" }}>
            <img src={sample2} alt="Imagen 2" style={{ width: isMobile ? "40vh" : "50vh", height: isMobile ? "40vh" : "50vh", objectFit: "cover", border: "1px solid #ccc", borderRadius: "4vh" }} />
            <div style={{ position: "absolute", top: 0, left: isMobile ? "4vh": "0", width: isMobile ? "40vh" : "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "4vh" }}></div>
            <div style={{ fontSize: "6vh", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "#FF69B4", zIndex: 1, fontFamily: "Ras-Med" }}>Bodas</div>
          </a>
        </div>
        <div style={{ margin: isMobile ? "3vh 0" : "0 3vh", textAlign: "center", position: "relative", flexBasis: isMobile ? "100%" : "auto" }}>
          <a href="https://1drv.ms/f/s!Av8KC8UeANmogZk56aQBJ78w947xoQ?e=la6wfz" style={{ textDecoration: "none" }}>
            <img src={sample3} alt="Imagen 3" style={{ width: isMobile ? "40vh" : "50vh", height: isMobile ? "40vh" : "50vh", objectFit: "cover", border: "1px solid #ccc", borderRadius: "4vh" }} />
            <div style={{ position: "absolute", top: 0, left: isMobile ? "4vh": "0", width: isMobile ? "40vh" : "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "4vh" }}></div>
            <div style={{ fontSize: "6vh", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "#FF69B4", zIndex: 1, fontFamily: "Ras-Med" }}>Empresarial</div>
          </a>
        </div>
      </div>
    </div>
  );
};
