import React, { useState, useEffect } from "react";
import test from "../assets/img/test.jpg";
import logoneural from "../assets/img/LogoNeural.png"

export const Comentarios = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = (e) => {
      setIsMobile(e.matches);
    };

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <h1 style={{ color: "#444", fontFamily:"Ras-Med", marginTop: isMobile ? "20vh" : "-1vh", fontSize: isMobile ? "5vh" : "7vh" }}>Comentarios</h1>
      <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center", alignItems: "center" }}>
        <div style={{ backgroundColor: "#eee", width: isMobile ? "80vw" : "60vh", margin: "5vh", borderRadius: isMobile ? "3vh" : "5vh", padding: "2vh", boxSizing: "border-box" }}>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "2vh" }}>
            <div style={{ width: isMobile ? "15vw" : "10vh", height: isMobile ? "15vw" : "10vh", borderRadius: "50%", overflow: "hidden", marginRight: "2vh", marginLeft: isMobile ? "2vh" : "1vh" }}>
              <img src={logoneural} alt="Avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
            </div>
            <div>
              <h3 style={{ fontFamily:"Ras-Med", fontSize: isMobile ? "3vh" : "4vh", marginTop: isMobile ? "2vh" : null, marginLeft: isMobile ? "2vh" : null }}>Proximamente.</h3>
              <p style={{ fontFamily:"Ras-Classic", fontSize: isMobile ? "2.5vh" : "3vh", marginLeft: isMobile ? "3vh" : null }}>Proximamente.</p>
            </div>
          </div>
          <p style={{ fontFamily:"Ras-Classic", fontSize: isMobile ? "2vh" : "2.5vh"}}>Proximamente.</p>
        </div>
        <div style={{ backgroundColor: "#eee", width: isMobile ? "80vw" : "60vh", margin: "5vh", borderRadius: isMobile ? "3vh" : "5vh", padding: "2vh", boxSizing: "border-box" }}>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "2vh" }}>
            <div style={{ width: isMobile ? "15vw" : "10vh", height: isMobile ? "15vw" : "10vh", borderRadius: "50%", overflow: "hidden", marginRight: "2vh", marginLeft: isMobile ? "2vh" : "1vh" }}>
              <img src={logoneural} alt="Avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
            </div>
            <div>
              <h3 style={{ fontFamily:"Ras-Med", fontSize: isMobile ? "3vh" : "4vh", marginTop: isMobile ? "2vh" : null, marginLeft: isMobile ? "2vh" : null }}>Proximamente.</h3>
              <p style={{ fontFamily:"Ras-Classic", fontSize: isMobile ? "2.5vh" : "3vh", marginLeft: isMobile ? "3vh" : null }}>Proximamente.</p>
            </div>
          </div>
          <p style={{ fontFamily:"Ras-Classic", fontSize: isMobile ? "2vh" : "2.5vh"}}>Proximamente.</p>
        </div>
      </div>
    </div>
  )
}
