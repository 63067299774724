import { Inicio } from "./components/Inicio"
import { Video } from "./components/Video"
import { Servicios } from "./components/Servicios"
import { QuienesSomos } from "./components/QuienesSomos"
import { Comentarios } from "./components/Comentarios"
import { Foot } from "./components/foot"
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <div>
      <Inicio/>
      <Video/>
      <Servicios/>
      {/*<QuienesSomos/>  (comentado temporalmente) */}
      <Comentarios/>
      <Foot/>
    </div>
  );
}

export default App;



/* Quitar la scroll bar */