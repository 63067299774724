import React from "react";

export const Video = () => {
  return (
    <div style={{ margin: "10vh auto", maxWidth: "80vw" }}>
      <div style={{ position: "relative", paddingTop: "56.25%", height: "0" }}>
        <iframe
          style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
          src="https://www.youtube.com/embed/IZkHxWPtFEw"
          title="video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}